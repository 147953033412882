$(function() {
  $(document).on('change', '#user_company_id', function() {
    var value = $("#user_company_id option:selected").val();
    $("#user_acl_id").empty();
    $.ajax({
      url: "/acls/ajax_acls",
      type: "GET",
      data: '&company_id=' + value
    })
    console.log(value);
  });
});
