$(function() {
  $(document).on('change', '#project_customer_id', function(evt) {
    var customer_id = $("#project_customer_id").val();
    var project_id = $("#project_id").val();
    $.ajax({
      url: "/projects/ajax_projects_sites",
      type: "GET",
      data: '&customer_id=' + customer_id + '&project_id=' + project_id
    })
  });
});
$(document).on('turbolinks:load', function() {
  $('.tree-project-expanded').treegrid({
    expanderExpandedClass: 'put_chevron_up',
    expanderCollapsedClass: 'put_chevron_down',
    initialState: 'collapsed'
  });
  $('.tree-project-collapsed').treegrid({
    expanderExpandedClass: 'put_chevron_up',
    expanderCollapsedClass: 'put_chevron_down',
    initialState: 'collapsed',
    treeColumn: 1
  });
});
