$(document).on('change', '#offer_product_batch_id', function(evt) {
  var batch_id = $("#offer_product_batch_id").val();
  if (batch_id != null && batch_id != undefined && batch_id != "") {
    $.ajax({
      url: "ajax_batch",
      type: "GET",
      data: '&batch_id=' + batch_id
    })
  }
});
$(document).on('change', '#offer_product_product_id', function(evt) {
  var product_id = $("#offer_product_product_id").val();
  if (product_id != null && product_id != undefined && product_id != "") {
    $.ajax({
      url: "ajax_product",
      type: "GET",
      data: '&product_id=' + product_id
    })
  }
});
