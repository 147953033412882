jQuery(function($) {
  var locale = getParameterByName('locale');

  $(document).on('change', '#financial_report_customer_ref_check', function(evt) {
    var customer_id = $("#financial_report_customer_ref_check").val();
    $.ajax({
      url: "financial_reports/ajax_projects_by_customer",
      type: "GET",
      data: '&customer_id=' + customer_id
    })
  });
});
