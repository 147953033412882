jQuery(function($) {
  var locale = getParameterByName('locale');
  $(document).on('click', '.tj_down', function(event) {
    event.stopPropagation();
    var tj_id = this.id.replace('tj_down_', '');
    if (locale == 'it') {
      var msg = "Sei sicuro di non voler registrare l'attività?";
    } else {
      var msg = "Are you sure you don't want to record your activity?";
    }
    bootbox.confirm({
      size: 'small',
      message: msg,
      callback: function(result) {
        if (result) {
          $.ajax({
            url: 'tj_down',
            type: 'GET',
            data: 'tj_id=' + tj_id + '&locale=' + locale,
          });
        }
      },
    });
  });
});
