$(function() {
  $(document).on('change', '#assigned_task_customer_ref_check', function(evt) {
    var customer_id = $("#assigned_task_customer_ref_check").val();
    $.ajax({
      url: "ajax_projects_sites_assigned_task",
      type: "GET",
      data: '&customer_id=' + customer_id
    })
  });
});

window.set_info_assigned_task = function(assigned_task_id) {
  var locale = getParameterByName('locale');
  $.ajax({
    url: "set_request_task_jobs",
    type: "GET",
    data: 'assigned_task_id=' + assigned_task_id + '&locale=' + locale
  })
}
