$(document).on("click", ".calendar_filter", function (event) {
  var calendar_filter_id = this.id.replace("filter_id_", ".cd_type_id_");
  console.log(calendar_filter_id);
  if ($(this).hasClass("grayout")) {
    $(this).removeClass("grayout");
    $(calendar_filter_id).removeClass("hide-hide");
  } else {
    $(this).addClass("grayout");
    $(calendar_filter_id).addClass("hide-hide");
  }
});
