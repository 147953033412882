var locale = getParameterByName('locale');
//Bottone start
$(document).on('click', '.quick_player_play', function(event) {
  event.stopPropagation();
  if ($(this).hasClass('btn-success')) {
    var user_extra_id = this.id.replace('quick_player_play_', '');
    $(this).removeClass('btn-success');
    $(this).addClass('disabled');
    $(this).addClass('btn-muted');

    $('.quick_player_pause').removeClass('disabled');
    $('.quick_player_pause').removeClass('btn-muted');
    $('.quick_player_pause').addClass('btn-warning');
    $('.quick_player_stop').removeClass('disabled');
    $('.quick_player_stop').removeClass('btn-muted');
    $('.quick_player_stop').addClass('btn-danger');
    // gestione helpdesk
    $(".assigned_task_start").removeClass('disabled');
    //Mantenere questo ordine per le successive due righe
    $(".card.card-active").addClass('card-pause');
    $(".card").removeClass('card-active');
    $(".assigned_task_add_time").removeClass('disabled');
    $(".card.card-pause .assigned_task_add_time").addClass('disabled');
    $(".assigned_task_pause").addClass('disabled');
    $.ajax({
      url: '/start_temporary_job',
      type: 'GET',
      data: 'user_extra_id=' + user_extra_id + '&locale=' + locale,
    });
  }
});

//Bottone pause
$(document).on('click', '.quick_player_pause', function(event) {
  event.stopPropagation();
  if ($(this).hasClass('btn-warning')) {
    var user_extra_id = this.id.replace('quick_player_pause_', '');
    $(this).removeClass('btn-warning');
    $(this).addClass('disabled');
    $(this).addClass('btn-muted');
    $('.quick_player_play').removeClass('disabled');
    $('.quick_player_play').removeClass('btn-muted');
    $('.quick_player_play').addClass('btn-success');
    $('.quick_player_stop').removeClass('disabled');
    $('.quick_player_stop').removeClass('btn-muted');
    $('.quick_player_stop').addClass('btn-danger');
    $.ajax({
      url: '/pause_temporary_job',
      type: 'GET',
      data: 'user_extra_id=' + user_extra_id + '&locale=' + locale,
    });
  }
});

//Bottone stop
$(document).on('click', '.quick_player_stop', function(event) {
  event.stopPropagation();
  if ($(this).hasClass('btn-danger')) {
    var user_extra_id = this.id.replace('quick_player_stop_', '');
    $(this).removeClass('btn-danger');
    $(this).addClass('disabled');
    $(this).addClass('btn-muted');
    $('.quick_player_play').removeClass('disabled');
    $('.quick_player_play').removeClass('btn-muted');
    $('.quick_player_play').addClass('btn-success');
    $('.quick_player_pause').removeClass('btn-warning');
    $('.quick_player_pause').addClass('disabled');
    $('.quick_player_pause').addClass('btn-muted');
    $.ajax({
      url: '/stop_temporary_job',
      type: 'GET',
      data: 'user_extra_id=' + user_extra_id + '&locale=' + locale,
    });
  }
});
