jQuery(function($) {
  var locale = getParameterByName("locale");
  if ($('body').hasClass("leave_times", "new")) {
    var leave_time_type_id = $("#leave_time_leave_time_type_id").val();
    if (leave_time_type_id != "" && leave_time_type_id != undefined) {
      $.ajax({
        url: "/leave_time_types/get_class_type",
        type: "GET",
        data: "&id=" + leave_time_type_id,
      });
    }
  }

  //up
  $(document).on("click", ".manager_approve_up.pe-auto", function(event) {
    event.stopPropagation();
    var lt_id = this.id.replace("manager_approve_up_", "");
    var value;
    if ($(this).hasClass("off")) {
      $(this).removeClass("off");
      $(this).addClass("on");
      $("#manager_approve_down_" + lt_id).removeClass("on");
      $("#manager_approve_down_" + lt_id).addClass("off");
      value = 1;
    } else {
      $("#manager_approve_up_" + lt_id).removeClass("on");
      $("#manager_approve_up_" + lt_id).addClass("off");
      value = 2;
    }
    $.ajax({
      url: "manager_approve_up",
      type: "GET",
      data: "id=" + lt_id + "&value=" + value + "&locale=" + locale,
    });
  });

  //down
  $(document).on("click", ".manager_approve_down.pe-auto", function(event) {
    event.stopPropagation();
    var lt_id = this.id.replace("manager_approve_down_", "");
    var value;
    if ($(this).hasClass("off")) {
      $(this).removeClass("off");
      $(this).addClass("on");
      $("#manager_approve_up_" + lt_id).removeClass("on");
      $("#manager_approve_up_" + lt_id).addClass("off");
      value = 0;
    } else {
      $(this).removeClass("on");
      $(this).addClass("off");
      value = 2;
    }
    $.ajax({
      url: "manager_approve_down",
      type: "GET",
      data: "id=" + lt_id + "&value=" + value + "&locale=" + locale,
    });
  });

  //toggle
  $(document).on("click", ".manager_stand_by_toggle.pe-auto", function(event) {
    event.stopPropagation();
    var lt_id = this.id.replace("stand_by_toggle_", "");
    var value;
    if ($(this).hasClass("off")) {
      $(this).removeClass("off");
      $(this).addClass("on");
      value = 1;
    } else {
      $(this).removeClass("on");
      $(this).addClass("off");
      value = 0;
    }
    $.ajax({
      url: "manager_stand_by_toggle",
      type: "GET",
      data: "id=" + lt_id + "&value=" + value + "&locale=" + locale,
    });
  });

  $(document).on("change", "#leave_time_leave_time_type_id", function(evt) {
    var leave_time_type_id = $("#leave_time_leave_time_type_id").val();
    if (leave_time_type_id != "" && leave_time_type_id != undefined) {
      $.ajax({
        url: "/leave_time_types/get_class_type",
        type: "GET",
        data: "&id=" + leave_time_type_id,
      });
    }
  });
  $(document).on("change", "#leave_time_sent_from_manager", function(evt) {
    let checked = $("#leave_time_sent_from_manager").is(":checked");
    if (checked) {
      $(".leave_time_user").hide();
    } else {
      $(".leave_time_user").show();
    }
  });
  $(document).on("change", "#leave_time_sent_from_company", function(evt) {
    let checked = $("#leave_time_sent_from_company").is(":checked");
    if (checked) {
      $(".leave_time_user").hide();
    } else {
      $(".leave_time_user").show();
    }
  });
});
