jQuery(function($) {
  var locale = getParameterByName('locale');

  ////////////
  //HELPDESK//
  ////////////
  // Gestione click su lista assigned_task
  $(document).on({
    click: function() {
      var assigned_task_id = this.id.replace('assigned_task_', '');
      if (!assigned_task_id.includes("todo")) {
        set_info_assigned_task(assigned_task_id);
        $(".card").removeClass("viewed");
        $(this).addClass("viewed");
      }
    },
    mouseenter: function() {
      $(this).addClass("inside");
    },
    mouseleave: function() {
      $(this).removeClass("inside");
    }
  }, '#assigned_tasks_list li');

  //Bottone close assigned task
  $(document).on('click', '.assigned_task_close', function(event) {
    event.stopPropagation();
    var assigned_task_id = this.id.replace('assigned_task_close_', '');
    if (locale == 'it') {
      var msg = "Sei sicuro di voler chiudere l'attività assegnata?";
    }
    else {
      var msg = 'Are you sure you want close the assigned task?';
    }
    bootbox.confirm(msg, function(result) {
      if (result) {
        $.ajax({
          url: "close_request_task_job",
          type: "GET",
          data: 'assigned_task_id=' + assigned_task_id + '&locale=' + locale
        });
      }
    }
    );
  });

  //Bottone start
  $(document).on('click', '.assigned_task_start', function(event) {
    event.stopPropagation();
    if (!$(this).hasClass('disabled')) {
      var assigned_task_id = this.id.replace('assigned_task_start_', '');
      $(".assigned_task_start").removeClass('disabled');
      $(".card.card-active").addClass('card-pause');
      $(".card").removeClass('card-active');
      $(".assigned_task_add_time").removeClass('disabled');
      $(".card.card-pause .assigned_task_add_time").addClass('disabled');
      $(this).addClass('disabled');
      $(".assigned_task_pause").addClass('disabled');
      $("#assigned_task_add_time_" + assigned_task_id).addClass('disabled');
      $("#assigned_task_stop_" + assigned_task_id).removeClass('disabled');
      $("#assigned_task_pause_" + assigned_task_id).removeClass('disabled');
      $("#assigned_task_" + assigned_task_id).removeClass('card-default');
      $("#assigned_task_" + assigned_task_id).removeClass('card-pause');
      $("#assigned_task_" + assigned_task_id).addClass('card-active');
      // gestione quick_player
      // Metto in pausa i temporanei
      if ($('.quick_player_play').hasClass('disabled')) {
        $('.quick_player_stop').removeClass('disabled');
        $('.quick_player_stop').removeClass('btn-muted');
        $('.quick_player_stop').addClass('btn-danger');
        $('.quick_player_play').removeClass('disabled');
        $('.quick_player_play').removeClass('btn-muted');
        $('.quick_player_play').addClass('btn-success');
        $('.quick_player_pause').removeClass('btn-warning');
        $('.quick_player_pause').addClass('disabled');
        $('.quick_player_pause').addClass('btn-muted');
      }
      $.ajax({
        url: "start_request_task_job",
        type: "GET",
        data: 'assigned_task_id=' + assigned_task_id + '&locale=' + locale
      });
    }
  });

  //Bottone pause
  $(document).on('click', '.assigned_task_pause', function(event) {
    event.stopPropagation();
    if (!$(this).hasClass('disabled')) {
      var assigned_task_id = this.id.replace('assigned_task_pause_', '');
      $(this).addClass('disabled');
      $("#assigned_task_stop_" + assigned_task_id).removeClass('disabled');
      $("#assigned_task_start_" + assigned_task_id).removeClass('disabled');
      $("#assigned_task_" + assigned_task_id).removeClass('card-active');
      $("#assigned_task_" + assigned_task_id).removeClass('card-default');
      $("#assigned_task_" + assigned_task_id).addClass('card-pause');
      $(".assigned_task_add_time").removeClass('disabled');
      $(".card.card-pause .assigned_task_add_time").addClass('disabled');
      $("#assigned_task_add_time_" + assigned_task_id).addClass('disabled');
      $.ajax({
        url: "pause_request_task_job",
        type: "GET",
        data: 'assigned_task_id=' + assigned_task_id + '&locale=' + locale
      });
    }
  });

  //Bottone stop
  $(document).on('click', '.assigned_task_stop', function(event) {
    event.stopPropagation();
    if (!$(this).hasClass('disabled')) {
      var assigned_task_id = this.id.replace('assigned_task_stop_', '');
      $(this).addClass('disabled');
      $(".assigned_task_add_time").removeClass('disabled');
      $(".assigned_task_add_time").removeClass('disabled');
      $("#assigned_task_start_" + assigned_task_id).removeClass('disabled');
      $("#assigned_task_pause_" + assigned_task_id).addClass('disabled');
      $("#assigned_task_" + assigned_task_id).removeClass('card-active');
      $("#assigned_task_" + assigned_task_id).removeClass('card-pause');
      $("#assigned_task_" + assigned_task_id).addClass('card-default');
      $(".card.card-active .assigned_task_add_time").addClass('disabled');
      $(".card.card-pause .assigned_task_add_time").addClass('disabled');
      $.ajax({
        url: "stop_request_task_job",
        type: "GET",
        data: 'assigned_task_id=' + assigned_task_id + '&locale=' + locale
      });
    }
  });

  $(document).on('click', '#job_submit', function() {
    $('#modal-window').modal('hide');
  });

  ///////////
  //PLANNER//
  ///////////

  // Gestione click su lista request
  $(document).on({
    click: function() {
      var id = this.id;
      set_info_request_task(id);
    },
    mouseenter: function() {
      $(this).addClass("inside");
    },
    mouseleave: function() {
      $(this).removeClass("inside");
    }
  }, '#request_and_task li');

  //////////
  //TICKET//
  //////////

  $(document).on('click', '.ticket-show-description', function(event) {
    event.stopPropagation();
    var req_id = this.id.replace('button-show-description-', '');
    if ($("#ticket-description-" + req_id).hasClass('d-none')) {
      $(this).addClass('active');
      $("#ticket-info-" + req_id).removeClass('d-none');
      $("#ticket-description-" + req_id).removeClass('d-none');
      $("#ticket-short-description-" + req_id).addClass('d-none');
    } else {
      $(this).removeClass('active');
      $("#ticket-description-" + req_id).addClass('d-none');
      $("#ticket-short-description-" + req_id).removeClass('d-none');
      if ($("#ticket-description-" + req_id).hasClass('d-none') && (!$(".ticket-tasks-" + req_id).length || $(".ticket-tasks-" + req_id).first().hasClass('d-none')) && (!$(".ticket-tasks-" + req_id).length || $(".ticket-users-" + req_id).first().hasClass('d-none')) && $("#ticket-time-" + req_id).hasClass('d-none')) {
        $("#ticket-info-" + req_id).addClass('d-none');
      }
    }
  });

  $(document).on('click', '.ticket-show-tasks', function(event) {
    event.stopPropagation();
    var req_id = this.id.replace('button-show-tasks-', '');
    if ($(".ticket-tasks-" + req_id).first().hasClass('d-none')) {
      $(this).addClass('active');
      $("#ticket-info-" + req_id).removeClass('d-none');
      $(".ticket-tasks-" + req_id).removeClass('d-none');
    } else {
      $(this).removeClass('active');
      $(".ticket-tasks-" + req_id).addClass('d-none');
      if ($("#ticket-description-" + req_id).hasClass('d-none') && (!$(".ticket-tasks-" + req_id).length || $(".ticket-tasks-" + req_id).first().hasClass('d-none')) && (!$(".ticket-tasks-" + req_id).length || $(".ticket-users-" + req_id).first().hasClass('d-none')) && $("#ticket-time-" + req_id).hasClass('d-none')) {
        $("#ticket-info-" + req_id).addClass('d-none');
      }
    }
  });

  $(document).on('click', '.ticket-show-users', function(event) {
    event.stopPropagation();
    var req_id = this.id.replace('button-show-users-', '');
    if ($(".ticket-users-" + req_id).first().hasClass('d-none')) {
      $(this).addClass('active');
      $("#ticket-info-" + req_id).removeClass('d-none');
      $(".ticket-users-" + req_id).removeClass('d-none');
    } else {
      $(this).removeClass('active');
      $(".ticket-users-" + req_id).addClass('d-none');
      if ($("#ticket-description-" + req_id).hasClass('d-none') && (!$(".ticket-tasks-" + req_id).length || $(".ticket-tasks-" + req_id).first().hasClass('d-none')) && (!$(".ticket-tasks-" + req_id).length || $(".ticket-users-" + req_id).first().hasClass('d-none')) && $("#ticket-time-" + req_id).hasClass('d-none')) {
        $("#ticket-info-" + req_id).addClass('d-none');
      }
    }
  });

  $(document).on('click', '.ticket-show-time', function(event) {
    event.stopPropagation();
    var req_id = this.id.replace('button-show-time-', '');
    if ($("#ticket-time-" + req_id).hasClass('d-none')) {
      $(this).addClass('active');
      $("#ticket-info-" + req_id).removeClass('d-none');
      $("#ticket-time-" + req_id).removeClass('d-none');
      $("#ticket-time-" + req_id).removeClass('d-none');
    } else {
      $(this).removeClass('active');
      $("#ticket-time-" + req_id).addClass('d-none');
      $("#ticket-time-" + req_id).addClass('d-none');
      if ($("#ticket-description-" + req_id).hasClass('d-none') && (!$(".ticket-tasks-" + req_id).length || $(".ticket-tasks-" + req_id).first().hasClass('d-none')) && (!$(".ticket-tasks-" + req_id).length || $(".ticket-users-" + req_id).first().hasClass('d-none')) && $("#ticket-time-" + req_id).hasClass('d-none')) {
        $("#ticket-info-" + req_id).addClass('d-none');
      }
    }
  });

  $(document).on('click', '#todos-list-toggle', function(event) {
    event.stopPropagation();
    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
      $("#container_todos_list").addClass('d-none');
      $("#add-todo").addClass('d-none');
      $("#container_assigned_tasks_list").addClass("animate__animated animate__fadeIn");
      $("#container_assigned_tasks_list").removeClass('d-none');
    } else {
      $(this).addClass('active');
      $("#container_assigned_tasks_list").addClass('d-none');
      $("#container_todos_list").addClass("animate__animated animate__fadeIn");
      $("#container_todos_list").removeClass('d-none');
      $("#add-todo").addClass("animate__animated animate__fadeIn");
      $("#add-todo").removeClass('d-none');
    }
  });

  $(document).on('click', '.request-state-button', function(event) {
    event.stopPropagation();
    var req_state_id = this.id.replace('request-state-button-', '');
    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
      $("#request-state-col-" + req_state_id).addClass('d-none');
    } else {
      $(this).addClass('active');
      $("#request-state-col-" + req_state_id).removeClass('d-none');
    }
  });

  $(document).on('click', '.task-state-button', function(event) {
    event.stopPropagation();
    var task_state_id = this.id.replace('task-state-button-', '');
    if ($(this).hasClass('active')) {
      $(this).removeClass('active');
      $("#task-state-col-" + task_state_id).addClass('d-none');
    } else {
      $(this).addClass('active');
      $("#task-state-col-" + task_state_id).removeClass('d-none');
    }
  });

  $(document).on('change', '#customer_id', function(evt) {
    var customer_id = $("#customer_id").val();
    if (customer_id != null && customer_id != undefined && customer_id != "") {
      $.ajax({
        url: "ajax_projects_sites_by_customer",
        type: "GET",
        data: '&customer_id=' + customer_id
      })
    }
  });
});
