jQuery(function($) {
  var locale = getParameterByName('locale');

  ////////////////////
  // PROJECT LEADER //
  ////////////////////

  //up
  $(document).on('click', '.pl_job_up.pe-auto', function(event) {
    event.stopPropagation();
    var job_id = this.id.replace('pl_job_up_', '');
    var value;
    if ($(this).hasClass('off')) {
      $(this).removeClass('off');
      $(this).addClass('on');
      $('#pl_job_down_' + job_id).removeClass('on');
      $('#pl_job_down_' + job_id).addClass('off');
      value = 1;
    } else {
      $(this).removeClass('on');
      $(this).addClass('off');
      value = 2;
    }
    $.ajax({
      url: 'pl_job_up',
      type: 'GET',
      data: 'job_id=' + job_id + '&value=' + value + '&locale=' + locale,
    });
  });

  //down
  $(document).on('click', '.pl_job_down.pe-auto', function(event) {
    event.stopPropagation();
    var job_id = this.id.replace('pl_job_down_', '');
    var value;
    if ($(this).hasClass('off')) {
      $(this).removeClass('off');
      $(this).addClass('on');
      $('#pl_job_up_' + job_id).removeClass('on');
      $('#pl_job_up_' + job_id).addClass('off');
      value = 0;
    } else {
      $(this).removeClass('on');
      $(this).addClass('off');
      value = 2;
    }
    $.ajax({
      url: 'pl_job_down',
      type: 'GET',
      data: 'job_id=' + job_id + '&value=' + value + '&locale=' + locale,
    });
  });

  /////////////////////
  // FINANCE MANAGER //
  /////////////////////

  //up
  $(document).on('click', '.fm_job_up.pe-auto', function(event) {
    event.stopPropagation();
    var job_id = this.id.replace('fm_job_up_', '');
    var msg = $('#fm_job_comment_' + job_id).data('bs-original-title');
    var value;
    if (msg === undefined){
      if ($('#fm_job_up_' + job_id).hasClass('off')) {
        $('#fm_job_up_' + job_id).removeClass('off');
        $('#fm_job_up_' + job_id).addClass('on');
        $('#fm_job_down_' + job_id).removeClass('on');
        $('#fm_job_down_' + job_id).addClass('off');
        value = 1;
      } else {
        $('#fm_job_up_' + job_id).removeClass('on');
        $('#fm_job_up_' + job_id).addClass('off');
        value = 2;
      }
      $.ajax({
        url: 'fm_job_up',
        type: 'GET',
        data: 'job_id=' + job_id + '&value=' + value + '&locale=' + locale,
      });
    }
    else
    {
      bootbox.confirm({
        size: 'small',
        message: msg,
        callback: function(result) {
          if (result) {
            if ($('#fm_job_up_' + job_id).hasClass('off')) {
              $('#fm_job_up_' + job_id).removeClass('off');
              $('#fm_job_up_' + job_id).addClass('on');
              $('#fm_job_down_' + job_id).removeClass('on');
              $('#fm_job_down_' + job_id).addClass('off');
              value = 1;
            } else {
              $('#fm_job_up_' + job_id).removeClass('on');
              $('#fm_job_up_' + job_id).addClass('off');
              value = 2;
            }
            $.ajax({
              url: 'fm_job_up',
              type: 'GET',
              data: 'job_id=' + job_id + '&value=' + value + '&locale=' + locale,
            });
          }
        },
      });
    }
  });

  //down
  $(document).on('click', '.fm_job_down.pe-auto', function(event) {
    event.stopPropagation();
    var job_id = this.id.replace('fm_job_down_', '');
    var value;
    if ($(this).hasClass('off')) {
      $(this).removeClass('off');
      $(this).addClass('on');
      $('#fm_job_up_' + job_id).removeClass('on');
      $('#fm_job_up_' + job_id).addClass('off');
      value = 0;
    } else {
      $(this).removeClass('on');
      $(this).addClass('off');
      value = 2;
    }
    $.ajax({
      url: 'fm_job_down',
      type: 'GET',
      data: 'job_id=' + job_id + '&value=' + value + '&locale=' + locale,
    });
  });

  //toggle
  $(document).on('click', '.fm_stand_by_toggle.pe-auto', function(event) {
    event.stopPropagation();
    var job_id = this.id.replace('fm_stand_by_toggle_', '');
    var value;
    if ($(this).hasClass('off')) {
      $(this).removeClass('off');
      $(this).addClass('on');
      $(this)
        .parent()
        .parent()
        .addClass('stand_by');
      value = 1;
    } else {
      $(this).removeClass('on');
      $(this).addClass('off');
      $(this)
        .parent()
        .parent()
        .removeClass('stand_by');
      value = 0;
    }
    $.ajax({
      url: 'fm_stand_by_toggle',
      type: 'GET',
      data: 'job_id=' + job_id + '&value=' + value + '&locale=' + locale,
    });
  });

  ///////////////////////////
  // FINANCIAL REPORT VIEW //
  ///////////////////////////

  //toggle
  $(document).on('click', '.fr_bill_toggle.pe-auto', function(event) {
    event.stopPropagation();
    var fr_id = this.id.replace('fr_bill_toggle_', '');
    var value;
    if ($(this).hasClass('off')) {
      $(this).removeClass('off');
      $(this).addClass('on');
      value = 1;
    } else {
      $(this).removeClass('on');
      $(this).addClass('off');
      value = 0;
    }
    $.ajax({
      url: 'fr_bill_toggle',
      type: 'GET',
      data: 'fr_id=' + fr_id + '&value=' + value + '&locale=' + locale,
    });
  });

  // Multiple edit
  $(document).on('click', '#multiple_financial_button', function(event) {
    event.preventDefault();
    var checker = false;
    $('input.financial_report_check').each(function(i, el) {
      if($(el).is(":checked")) {
        checker = true;
      }
    });
    if (checker) {
      document.getElementById('multiple_financial_report_form').submit();
    } else {
      if (locale == 'it'){
        msg = "Selezionare almeno un elemento.";
      }
      else {
        msg = 'Select at least one element';
      }
      bootbox.alert(msg);
    }
  });

  ///////////////////////////////////////////

  $(document).on('change', '#job_customer_ref_check', function(evt) {
    var customer_id = $('#job_customer_ref_check').val();
    $.ajax({
      url: 'ajax_projects_sites_by_customer',
      type: 'GET',
      data: '&customer_id=' + customer_id,
    });
  });

  $(document).on('change', '#project_customer_ref_check', function(evt) {
    var customer_id = $('#project_customer_ref_check').val();
    $.ajax({
      url: 'ajax_projects_sites_by_customer',
      type: 'GET',
      data: '&customer_id=' + customer_id,
    });
  });
});
