jQuery(function($) {
  var locale = getParameterByName('locale');

  $(document).on('change', '#attachment_customer_ref_check', function(evt) {
    var customer_id = $('#attachment_customer_ref_check').val();
    $.ajax({
      url: 'ajax_projects_by_customer',
      type: 'GET',
      data: '&customer_id=' + customer_id,
    });
  });
});
