// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
$(document).on('keypress', '.simple_form.new_asset_item, .simple_form.edit_asset_item', function (e) {
  if (e.keyCode == 13) {
    return false;
  }
});
$(document).on('change', '#asset_item_asset_item_type_id', function(evt) {
  var struct_id = $("#asset_item_asset_item_type_id option:selected").val();
  var id = $("#asset_sddsd_item_id").val();
  $("#asset_item_data").empty();
  $.ajax({
    url: "ajax_asset_item_type",
    type: "GET",
    data: '&asset_item_type_id=' + struct_id + '&id=' + id
  })
});
// 
