// QUICK REQUEST
$(document).on('change', '#request_customer_id', function(evt) {
  var customer_id = $("#request_customer_id").val();
  if (customer_id != null && customer_id != undefined && customer_id != "") {
  $.ajax({
    url: "ajax_projects_sites",
    type: "GET",
    data: '&customer_id=' + customer_id
  })
  }
});

/* $(document).on('change', '#request_customer_id', function(evt) { */
/*   var customer_id = $("#request_customer_id").val(); */
/*   $("#open_tasks_project").fadeOut(); */
/*   if (customer_id != '') { */
/*     $.ajax({ */
/*       url: "ajax_open_tasks", */
/*       type: "GET", */
/*       data: '&customer_id=' + customer_id */
/*     }) */
/*   } else { */
/*     $("#open_tasks_customer").fadeOut(); */
/*   } */
/* }); */

/* $(document).on('change', '#request_tasks_attributes_0_project_id', function(evt) { */
/*   var project_id = $("#request_tasks_attributes_0_project_id option:selected").val(); */
/*   $("#open_tasks_customer").fadeOut(); */
/*   if (project_id != '') { */
/*     $.ajax({ */
/*       url: "ajax_open_tasks", */
/*       type: "GET", */
/*       data: '&project_id=' + project_id */
/*     }) */
/*   } else { */
/*     $("#open_tasks_project").fadeOut(); */
/*   } */
/* }); */

$(document).on('change', '#request_due_date', function(evt) {
  $("#request_tasks_attributes_0_due_date").val( $("#request_due_date").val() );
});

$(document).on('change', '#request_description', function(evt) {
  $("#request_tasks_attributes_0_description").val( $("#request_description").val() );
  $("#request_tasks_attributes_0_assigned_tasks_attributes_0_schedules_attributes_0_note").val( $("#request_description").val() );
});

$(document).on('change', '#request_priority_id', function(evt) {
  $("#request_tasks_attributes_0_priority_id").val( $("#request_priority_id option:selected").val() );
});


$(document).on('turbolinks:load', function() {
  if ( $( "#request_description" ).length ) {
    enlargeTextarea($("#request_description"));
  }
  if ( $( "#request_note" ).length ) {
    enlargeTextarea($("#request_note"));
  }
  if ( $( "#request_tasks_attributes_0_description" ).length ) {
    enlargeTextarea($("#request_tasks_attributes_0_description"));
  }
  if ( $( "#request_tasks_attributes_0_note" ).length ) {
    enlargeTextarea($("#request_tasks_attributes_0_note"));
  }
  if (typeof $.browser == 'undefined') {
    $.browser = {};
  }
});

window.set_info_request_task = function(id) {
  var locale = getParameterByName('locale');
  var type = '';
  if (id.includes('request_')) {
    type = 'R';
    id = id.replace('request_','');
  }
  else {
    type = 'T';
    id = id.replace('task_','');
  }
  $.ajax({
    url: "set_request_task",
    type: "GET",
    data: 'type=' + type + '&id=' + id + '&locale=' + locale
  })
}
