jQuery(function($) {
  var locale = getParameterByName('locale');
  $(document).on('click', '.sc_down.allowed', function(event) {
    event.stopPropagation();
    var schedule_id = this.id.replace('sc_down_', '');
    if (locale == 'it') {
      var msg = "Sei sicuro di non voler registrare l'attività?";
    } else {
      var msg = "Are you sure you don't want to record your activity?";
    }
    bootbox.confirm({
      size: 'small',
      message: msg,
      callback: function(result) {
        if (result) {
          $.ajax({
            url: 'sc_down',
            type: 'GET',
            data: 'schedule_id=' + schedule_id + '&locale=' + locale,
          });
        }
      },
    });
  });
});
