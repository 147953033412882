document.addEventListener("turbolinks:load", () => {
  pagy_elements = document.querySelectorAll(".pagination");
  pagy_elements.forEach((p) => {
    p.classList.add("pagination-sm");
    p.classList.add("text-uppercase");
    p.classList.add("fw-bold");
  });
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function(tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl, { html: true, boundary: "window", trigger: "hover" })
  })
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function(popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl)
  })
  $(".tree-expanded").treegrid({
    expanderExpandedClass: "put_chevron_up",
    expanderCollapsedClass: "put_chevorn_down",
    initialState: "expanded",
  });
  $(".tree-collapsed").treegrid({
    expanderExpandedClass: "put_chevron_up",
    expanderCollapsedClass: "put_chevron_down",
    initialState: "collapsed",
  });
  window.onscroll = function() {
    if ($(this).scrollTop() > 50) {
      $("#back-to-top").fadeIn("slow");
    } else {
      $("#back-to-top").fadeOut("slow");
    }
  };
  $("#back-to-top").on("click", function() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  });

  $(document).on("change", "#acl_detail_class_name", function() {
    var value = $("#acl_detail_class_name option:selected").val();
    $("#acl_detail_field_name").empty();
    $.ajax({
      url: "/acl_details/ajax_field_names",
      type: "GET",
      data: "&class_name=" + value,
    });
  });

  $(document).on("change", "[id$=city_id]", function() {
    var city_id = $("[id$=city_id]").val();
    $.ajax({
      url: "/zip_codes/ajax_zip_codes_for_city",
      type: "GET",
      data: "&city_id=" + city_id,
    });
  });

  $(document).on("click", ".reset_search_form", function() {
    resetForm($("#search_form"));
  });

  $(document).on("click", ".btn, .pagination", function(e) {
    e.stopPropagation();
  });

  if (document.body.classList.contains('logged')) {
    // timer per refresh job attivi
    setInterval(user_or_timer, 60000);
  }

  /* $(document).ajaxError(function(e, error) { */
  /*   switch (error.status) { */
  /*     case 401: { */
  /*       // unauthorised (possible timeout) */
  /*       location.reload(); */
  /*       break; */
  /*     } */
  /*     case 404: { */
  /*       // unauthorised (possible timeout) */
  /*       location.reload(); */
  /*       break; */
  /*     } */
  /*     case 406: { */
  /*       // unauthorised (possible timeout) */
  /*       location.reload(); */
  /*       break; */
  /*     } */
  /*     case 500: { */
  /*       // unauthorised (possible timeout) */
  /*       location.reload(); */
  /*       break; */
  /*     } */
  /*   } */
  /* }); */
});

$(document).on('focus', '#user_note', function() {
  $("#user_note_save_btn").removeClass('d-none');
});

$(document).on('focusout', '#user_note', function() {
  $("#user_note_save_btn").addClass('d-none');
  var user_note = encodeURIComponent($("#user_note").val());
  $.ajax({
    url: "/update_user_note",
    type: "GET",
    data: 'user_note=' + user_note,
    error: function(xhr, textStatus, errorThrown) {
      alert('Inserimento note personali fallito');
    }
  });
});

$(document).on("click", ".boolean-toggle", function(e) {
  id = $(this).data('id');
  column_name = $(this).data('column-name');
  model_name = $(this).data('model');
  with_null = $(this).data('with-null');
  $.ajax({
    url: "/boolean_toggle",
    type: "GET",
    data: "&id=" + id + "&column_name=" + column_name + "&model_name=" + model_name + "&with_null=" + with_null,
  });
});

if (navigator.serviceWorker) {
  navigator.serviceWorker.register("/service-worker.js", { scope: "/" })
    .then(() => navigator.serviceWorker.ready)
    .then((registration) => {
      if ("SyncManager" in window) {
        registration.sync.register("sync-forms");
      } else {
        console.log("This browser does not support background sync.")
      }
    }).then(() => console.log("[Companion]", "Service worker registered!"));
}

// FIX: Da controllare
// Reset form for filter
window.resetForm = function($form) {
  $form
    .find(
      "input[type=number], input:text, input.flatpickr-input, input:password, input:file, select, textarea"
    )
    .val("");
  $form
    .find("input:radio, input:checkbox")
    .removeAttr("checked")
    .removeAttr("selected");
    document.querySelectorAll('.tomselected').forEach((el) => {
      el.tomselect.clear();
    });
}

// Show ON on filters-button if filter is active
$(function() {
  var fields = $("#search_form").serializeArray();
  var is_blank = true;
  for (var i = 0; i < fields.length; i++) {
    if (fields[i].name != "locale" && fields[i].name != "utf8") {
      if (fields[i].value) {
        is_blank = false;
      }
    }
  }
  if (is_blank) {
    //$('#filters-button').append(': OFF');
  } else {
    $("#filters-button").append(": ON");
  }
});

// JSON pretty
window.prettyPrint = function(obj) {
  var ugly_json = JSON.parse(obj.val());
  var pretty_json = JSON.stringify(ugly_json, undefined, 2);
  obj.val(pretty_json);
  enlargeTextarea(obj);
}

// Enlarge TextArea
window.enlargeTextarea = function(obj) {
  obj[0].setAttribute(
    "style",
    "height:" + obj[0].scrollHeight + "px;overflow-y:hidden;"
  );
  obj.on("input", function() {
    obj[0].style.height = "auto";
    obj[0].style.height = obj[0].scrollHeight + "px";
  });
}

// Get parameter from url
window.getParameterByName = function(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
    results = regex.exec(location.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

window.user_or_timer = function() {
  $.ajax({
    type: 'GET',
    url: '/timer',
    data: {}
  });
}
