document.addEventListener("turbolinks:load", () => {
  var videoStream;
  var scanActive = false
  async function openCamera() {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: { facingMode: 'environment' }, audio: false });
      const videoElement = document.querySelector('video');
      videoElement.srcObject = stream;
      videoElement.play();
      videoStream = stream;
    } catch (err) {
      console.error('Errore nell\'apertura della videocamera:', err);
      alert('Errore nell\'apertura della videocamera: ' + err.message);
    }
  }

  async function scanQRCode() {
    if (!scanActive) return

    const video = document.querySelector('video');
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');

    if (video.readyState === video.HAVE_ENOUGH_DATA) {
      canvas.height = video.videoHeight;
      canvas.width = video.videoWidth;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const code = jsQR(imageData.data, imageData.width, imageData.height);

      if (code) {
        // console.log('QR code trovato:', code.data);
        stopScanning();
        window.location.href = "/qr/" + code.data;
      } else {
        requestAnimationFrame(scanQRCode);
      }
    } else {
      requestAnimationFrame(scanQRCode);
    }
  }
  function stopCamera() {
    scanActive = false
    if (videoStream) {
      let tracks = videoStream.getTracks();
      tracks.forEach(track => track.stop());
      const videoElement = document.querySelector('video');
      videoElement.srcObject = null;
    }
  }

  function startScanning() {
    scanActive = true
    openCamera().then(() => {
      requestAnimationFrame(scanQRCode);
    });
  }

  function stopScanning() {
    stopCamera();
  }

  $(document).on('click', '#cameraButton', function(event) {
    stopCamera();
    startScanning();
  });
  $(document).on('click', '#stopCameraButton', function(event) {
    stopCamera();
  });
});
