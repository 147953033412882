// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.

$(function() {
  $(document).on('change', '#acl_detail_class_name', function(evt) {
    var value = $("#acl_detail_class_name option:selected").val();
    $("#acl_detail_field_name").empty();
    $.ajax({
      url: "/acl_details/ajax_field_names",
      type: "GET",
      data: '&class_name=' + value
    })
  });
});
