$(document).on('change', '#offer_customer_id', function(evt) {
  var customer_id = $("#offer_customer_id").val();
  if (customer_id != null && customer_id != undefined && customer_id != "") {
  $.ajax({
    url: "ajax_sites_contacts",
    type: "GET",
    data: '&customer_id=' + customer_id
  })
  }
});
