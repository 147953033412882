// Place all the behaviors and hooks related to the matching controller here.
// All this logic will automatically be available in application.js.
$(document).on('turbolinks:load', function() {
  if ( $( "#asset_item_type_data_struct" ).length ) {
    prettyPrint($("#asset_item_type_data_struct"));
  }
});

$(document).on('change', '#asset_item_type_data_struct', function(evt) {
  prettyPrint($("#asset_item_type_data_struct"));
});

