window.TomSelect = require('tom-select');
$(document).on('turbolinks:load', function() {
  document.querySelectorAll('.tom-select').forEach((el) => {
    let settings = {
      plugins: ['clear_button'],
      maxItems: 1,
      maxOptions: null,
      selectOnTab: true,
      closeAfterSelect: true,
      hidePlaceholderOnSelect: true, // Nasconde il placeholder dopo la selezione
      preload: true,
      create: false,
      openOnFocus: true,
      highlight: true,
      allowEmptyOption: true,
    };
    new TomSelect(el, settings);
  });
  document.querySelectorAll('.tom-select-multi').forEach((el) => {
    let settings = {
      plugins: ['remove_button', 'clear_button'],
      maxOptions: null,
      selectOnTab: true,
      closeAfterSelect: false,
      hidePlaceholderOnSelect: true, // Nasconde il placeholder dopo la selezione
      preload: true,
      create: false,
      openOnFocus: true,
      highlight: true,
      allowEmptyOption: true,
    };
    new TomSelect(el, settings);
  });
  document.querySelectorAll('.tom-select-model').forEach((el) => {
    var queryParams = {}
    var searchIn;
    for (var i = 0; i < el.attributes.length; i++) {
      var attr = el.attributes[i];
      if (attr.name.startsWith('data-')) {
        var paramName = attr.name.substring(5);
        queryParams[paramName] = attr.value;
        if (paramName === 'search-in') {
          searchIn = attr.value;
        }
      }
    }
    delete queryParams['search-in'];
    var queryString = Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&');
    queryString = queryString.length > 0 ? '&' + queryString : '';
    let settings = {
      plugins: ['clear_button'],
      maxItems: 1,
      maxOptions: null,
      selectOnTab: true,
      closeAfterSelect: true,
      hidePlaceholderOnSelect: true, // Nasconde il placeholder dopo la selezione
      highlight: true,
      allowEmptyOption: false,
      create: false, // Disabilita la creazione di nuove opzioni
      valueField: 'id', // Campo utilizzato come valore dell'opzione selezionata
      labelField: 'description', // Campo utilizzato come etichetta dell'opzione
      searchField: 'description', // Campo utilizzato per la ricerca delle opzioni
      load: function(query, callback) {
        if (!query.length) return callback();
        fetch('/' + searchIn + '/search?q=' + query + queryString) // URL dell'API Rails per ottenere le opzioni
          .then(function(response) {
            return response.json();
          })
          .then(function(data) {
            callback(data.items); // Restituisce le opzioni ottenute dall'API
          });
      }
    };
    new TomSelect(el, settings);
  });
});
