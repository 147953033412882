$(document).on('turbolinks:load', function() {
  $(document).on('change', '#job_time_customer_ref_check', function(evt) {
    var customer_id = $("#job_time_customer_ref_check").val();
    $.ajax({
      url: "ajax_projects_sites_job_time",
      type: "GET",
      data: '&customer_id=' + customer_id
    })
  });
});

