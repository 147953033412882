
$(function() {
  //var request_id = $("#task_request_id option:selected").val();
  // Serve per propolare la lista se request_id già selezionato
  // non viene più usata perchè uso activerecord
  //
    //if (request_id) {
      //$.ajax({
        //url: "ajax_projects_sites",
        //type: "GET",
        //data: '&request_id=' + request_id
        //})
      //}
  $(document).on('change', '#task_request_id', function(evt) {
    var request_id = $("#task_request_id option:selected").val();
    $.ajax({
      url: "ajax_projects_sites",
      type: "GET",
      data: '&request_id=' + request_id
    })
  });

  /* $(document).on('change', '#task_project_id', function(evt) { */
  /*   var project_id = $("#task_project_id option:selected").val(); */
  /*   var task_id = $("#task_id").val(); */
  /*   $.ajax({ */
  /*     url: "ajax_tasks_parent", */
  /*     type: "GET", */
  /*     data: '&project_id=' + project_id + '&task_id=' + task_id */
  /*   }) */
  /*   if (project_id != '') { */
  /*     $.ajax({ */
  /*       url: "ajax_open_tasks", */
  /*       type: "GET", */
  /*       data: '&project_id=' + project_id */
  /*     }) */
  /*   } else { */
  /*     $("#open_tasks_project").fadeOut(); */
  /*   } */
  /* }) */

  $(document).on('change', '#task_customer_ref_check', function(evt) {
    var customer_id = $("#task_customer_ref_check").val();
    $.ajax({
      url: "ajax_projects_sites_by_customer",
      type: "GET",
      data: '&customer_id=' + customer_id
    })
  });

  // QUICK
  $(document).on('change', '#request_description', function(evt) {
    $("#request_tasks_attributes_0_description").val( $("#request_description").val() );
    $("#request_tasks_attributes_0_assigned_tasks_attributes_0_schedules_attributes_0_note").val( $("#request_description").val() );
  });

  $(document).on('turbolinks:load', function() {
    if ( $( "#task_description" ).length ) {
      enlargeTextarea($("#task_description"));
    }
    if ( $( "#task_note" ).length ) {
      enlargeTextarea($("#task_note"));
    }
    if (typeof $.browser == 'undefined') {
      $.browser = {};
    }
  });

});
